/**
 * List of events available in the whole app
 */
export enum EventName {
    ACTIVATE_ROUTE = "activateRoute",
    ROUTE_ACTIVATED = "routeActivated",
    ADD_TO_BLACKLIST = "addToBlacklist",
    AVAILABLE_GROUPINGS_REQUEST = "availableGroupingsRequest",
    AVAILABLE_GROUPINGS_RESPONSE = "availableGroupingsResponse",
    BOOKMARKS_LIST_CHANGED = "bookmarksListChanged",
    CHANGE_CLIENT = "changeClient",
    CHANGE_PAYMENT_METHOD = "changePaymentMethod",
    CHANGE_PLAN = "changePlan",
    CHANGE_PAYMENT_METHOD_AND_CHOSE_PLAN = "changePaymentMethodAndChoosePlan",
    CHAT_CLOSED = "chatClosed",
    CLOSE_ACTIVE_FORM = "closeActiveForm",
    COLUMNS_ORDER_TEMPLATE_REMOVED = "columnsOrderTemplateRemoved",
    CREATE_BASIC_CAMPAIGN_WITH_PRESELECTED_OFFERS = "createBasicCampaignWithPreselectedOffers",
    CREATE_CAMPAIGN_WITH_PRESELECTED_OFFERS = "createCampaignWithPreselectedOffers",
    EDIT_CAMPAIGN_WITH_AMBIGUOUS_URL = "editCampaignWithAmbiguousUrl",
    EDIT_WORKSPACE = "editWorkspace",
    ERROR_LOG_EXITED = "errorLogExited",
    FORM_CLOSED = "formClosed",
    HIDE_LOADING = "hideLoading",
    HIDE_PROMO_BAR = "hidePromoBar",
    HTTP_REQUEST_ERROR = "httpRequestError",
    MICRO_APP_READY = "ready",
    NAVIGATION_ENDED = "navigationEnded",
    NAVIGATION_REQUESTED = "navigationRequested",
    NAVIGATION_TO_AUTORULES = "navigateToAutoRules",
    NAVIGATE_TO_ENTITY_REPORT_BY_ID = "navigateToEntityReportSearchedById",
    OPEN_ADVANCED_REPORT_POPUP = "advancedReportPopup",
    OPEN_ASYNC_REPORT_POPUP = "openAsyncReportPopup",
    OPEN_CHAT = "openChat",
    OPEN_FULL_PAYMENT_FLOW = "fullPaymentFlow",
    OPEN_LOYALTY_PROGRAM_POPUP = "openLoyaltyProgramPopup",
    OPEN_PROMO = "openPromo",
    OPEN_PROMO_CHANGE_PLAN_POPUP = "openPromoChangePlanPopup",
    OPEN_PROMO_LINK = "openPromoLink",
    OPEN_SEARCH = "openSearch",
    // TO DO: Clean up after tags experiments
    OPEN_TAGS_POPUP = "openTagsPopup",
    OPEN_TRAFFIC_SOURCE_PRESETUP_POPUP = "openTrafficSourcePresetupPopup",
    OPEN_TAGS_COLUMN_TOOLTIP = "openTagsColumnTooltip",
    OPEN_IVT_DISCOVERY_POPUP = "openIVTDiscoveryPopup",
    OPEN_FRESHDESK_CONTACT_WIDGET = "openFreshdeskContactWidget",
    OPEN_SUPPORT_POPUP = "openSupportPopup",
    OPEN_LIMITED_PAYMENT_FLOW = "limitedPaymentFlow",
    PERFORM_NOTIFICATION_ACTION = "performNotificationAction",
    REFRESH_CUSTOM_CONVERSIONS = "refreshCustomConversions",
    RESTORE_BOT_BLACKLIST = "restoreBotBlacklist",
    SHOW_LOADING = "showLoading",
    SHOW_SEVERE_NOTIFICATION = "showSevereNotification",
    HIDE_SEVERE_NOTIFICATION = "hideSevereNotification",
    SHOW_TOAST_NOTIFICATION = "showToastNotification",
    HIDE_TOAST_NOTIFICATION = "hideToastNotification",
    SHOW_PROMO_BAR = "showPromoBar",
    SIDENAV_TOGGLED = "sidenavToggled",
    SIGN_OUT = "signOut",
    UPDATE_DATA_LAYER = "updateDataLayer",
    CREATE_SHARED_REPORT = "createSharedReport",
    OPEN_SHARED_REPORT_FEATURE_POPUP = "openSharedReportFeaturePopup",
    SHOW_DEMO_418_POPUP = "showDemo418Popup",
    SHOW_TRAFFIC_LOG_REQUEST_POPUP = "showTrafficLogRequestPopup",
    CREATE_BASIC_CAMPAIGN = "createBasicCampaign",
    CREATE_CAMPAIGN = "createCampaign",
    EDIT_CAMPAIGN = "editCampaign",
    BILLING_DATA_UPDATED = "billingDataUpdated",
    START_POLLING_PLAN_DATA = "startPollingPlanData",
    OPEN_REVIEW_PAGE_WITH_PRESELECTED_PLAN = "openReviewPageWithPreselectedPlan",
    UNSUBSCRIBE_FINISHED = "unsubscribeFinished",
    CHANGE_BILLING_INFO = "changeBillingInfo",
    HIDE_CONDITION_POPOVER = "hideConditionPopover",
    LOAD_REST_APPS_IF_NEEDED = "loadRestAppsIfNeeded",
    OPEN_PROMO_PAYMENT_FLOW = "openPromoPaymentFlow",
    RELOGIN = "relogin",
    RELOGIN_WITH_MFA = "reloginWithMfa",
    RELOGIN_WITH_GOOGLE = "reloginWithGoogle",
    SHARED_REPORT_EDITED = "sharedReportEdited",
    CREATED_OPTIMIZATION_RULE = "createOptimizationRule",
    SHOW_LOGIN_FORM = "showLoginForm",
    SHOW_MFA_FORM = "showMFAForm",
    LOGIN_SUCCESSFUL = "loginSuccessful",
    LOGIN_FAILURE = "loginFailure",
    USER_DATA_CHANGED = "userDataChanged",
    PURGE_CLIENT_SETUP_CACHE = "purgeClientSetupCache",
    OPEN_COLUMN_ORDER_POPUP = "openColumnsOrderPopup",
    PLAN_UPGRADED = "planUpgraded",
    OPEN_EXPORT_REPORT_POPUP = "openExportReportPopup",
    DUPLICATE_CAMPAIGN = "duplicateCampaign",
    CREATE_AFFILIATE_NETWORK = "createAffiliateNetwork",
    EDIT_AFFILIATE_NETWORK = "editAffiliateNetwork",
    DUPLICATE_AFFILIATE_NETWORK = "duplicateAffiliateNetwork",
    CREATE_FLOW = "createFlow",
    EDIT_FLOW = "editFlow",
    DUPLICATE_FLOW = "duplicateFlow",
    CREATE_LANDER = "createLander",
    EDIT_LANDER = "editLander",
    DUPLICATE_LANDER = "duplicateLander",
    CREATE_OFFER = "createOffer",
    EDIT_OFFER = "editOffer",
    DUPLICATE_OFFER = "duplicateOffer",
    CREATE_TRAFFIC_SOURCE = "createTrafficSource",
    EDIT_TRAFFIC_SOURCE = "editTrafficSource",
    DUPLICATE_TRAFFIC_SOURCE = "duplicateTrafficSource",
    UPDATE_COST = "updateCost",
    NEW_CAMPAIGN_POPUP = "newCampaignPopup",
    CUSTOM_COLUMNS_UPDATED = "customColumnsUpdated",
    AUTO_RULE_CREATED_EXTERNALLY = "autoRuleCreatedExternally",
    UPDATE_WORKSPACES_LIST = "updateWorkspacesList",
    WORKSPACE_UPDATED = "workspaceUpdated",
    UNSUBSCRIBE = "unsubscribe",
    PLAN_CHANGE_REQUESTED = "planChangeRequested",
    PAYMENT_METHOD_UPDATED = "paymentMethodUpdated",
    OPEN_INVOICE_DETAILS_CHANGE_REQUEST = "openInvoiceDetailsChangeRequest",
    ON_AUTOMIZER_LIST_CREATED = "onListCreated",
    FILL_INVOICE_DATA_AND_TOP_UP = "fillInvoiceDataAndTopUp",
    CUSTOM_DOMAINS_UPDATED = "customDomainsUpdated",
    DEDICATED_DOMAINS_UPDATED = "dedicatedDomainsUpdated",
    EDIT_SHARED_REPORT = "editSharedReport",
    AVAILABLE_SHARED_REPORT_COLUMNS_DEFINITION = "availableSharedReportColumnsDefinition",
    OPEN_IVT_DETAIL = "openIVTDetail",
    BULK_ACTION = "bulkAction",
    OPEN_OPTIMIZATION_POPUP = "openOptimizationPopup",
    UPDATE_FORM_HEIGHT = "updateFormHeight",
    CREATE_WANDUUM_CAMPAIGN = "createWanduumCampaign",
    EDIT_WANDUUM_CAMPAIGN = "editWanduumCampaign",
    DUPLICATE_WANDUUM_CAMPAIGN = "duplicateWanduumCampaign",
    MICRO_APP_ALL_READY = "allReady",
    AUTHENTICATION_TOKEN_CHANGED = "authenticationTokenChanged",
    USER_FLOW_ENDED = "userflowEnded",
    SHOW_NOTIFICATION_DETAILS_POPUP = "showNotificationDetailsPopup",
    COPY_TO_CLIPBOARD = "copyToClipboard",
    ANY_CAMPAIGN_CREATED = "anyCampaignCreated",
    ANY_INTEGRATION_ADDED = "anyIntegrationAdded",
    ANY_CUSTOM_DOMAIN_ADDED = "anyCustomDomainAdded",
    OPEN_SPS_MENU = "openSPSMenu",
    HIDE_SPS_MENU_BUTTON = "hideSPSMenuButton",
    REFRESH_CAMPAIGN_REPORT = "refreshCampaignReport",
    FEATURE_FLAGS_CHANGED = "featureFlagsChanged",
    NAVIGATE_TO_CAMPAIGN_REPORT = "navigateToCampaignReport",
    TRAFFIC_SOURCE_CREATED = "trafficSourceCreated",
    REFRESH_OFFER_REPORT = "refreshOfferReport",
    ANY_OFFER_CREATED = "anyOfferCreated",
    REFRESH_LANDER_REPORT = "refreshLanderReport",
    ANY_LANDER_CREATED = "anyLanderCreated",
}
